import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItem, Paper, TextField, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import AddNewSelectionIcon from "../assets/AddNewSelectionIcon";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UncheckCircle from "../assets/UncheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons"; // Import the upload icon
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { FormControl, InputLabel, Select, MenuItem, Input, withStyles } from '@material-ui/core';
import { CustomAddCancelButton } from "../shared/buttons";
import Papa from 'papaparse'; // Import PapaParse for CSV parsing
const useStyles = makeStyles({
  underline: {
      '&:after': {
          borderBottom: '1px solid #00D0BD',
      },
      '&:before': {
        borderBottom: '1px solid rgb(43, 48, 59)',
    },
    '&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before': {
      borderBottom: '2px solid #2b303b',
    },
  },
  select: {
    color: '#FFF',
  },
  button: {
      color: '#FFF',
      '&:hover': {
          color: '#00D0BD',
      },
  },
  dialogPaper: {
    position: 'fixed',
    maxWidth: '940px',
    width: '100%',
    height: '100%',
    flexShrink: 0,
    top: '0px',
    right: '0px',
    border: '1px solid #333840',
    background: '#1A2033',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1008,
    margin: '0px',
    overflowX: 'hidden',
  },
  dialogContent: {
    overflow: 'auto',
    flexGrow: 1,
    height: 'calc(100vh - 50px)'
  },
  dialogActions: {
    flexShrink: 0,
    justifyContent: 'center',
    width: '100%',
    bottom: '0px',
    position: 'absolute'
  },
});

const StyledInput = withStyles({
  underline: {
    '&:before': {
      borderBottom: '1px solid rgb(43, 48, 59)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #2b303b',
    },
    '&:after': {
      borderBottom: '1px solid #00D0BD',
    },
  },
})(Input); 

const ContactActions = ({userId, setContactsData, contactsData, selectedContacts, displayedContactsCount, displayContactOptions, handleContactSelectionClick, onDelete} ) => {
    const [isMobile, setIsMobile] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [newContact, setNewContact] = useState({ name: '', email: '', phone: '', doc_url: '' });
    const [isExpanded, setIsExpanded] = useState(false); // State to track expansion
    const [isSaveCurrentClick, setIsSaveCurrentClick] = useState(false);
    const [savedContactSelections, setSavedContactSelections] = useState([]);
    const [newSelectionName, setNewSelectionName] = useState('');
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [contactTypes, setContactTypes] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    //a new state variable to track whether the delete button has been clicked
    const [isDeleteClicked, setIsDeleteClicked] = useState(false);
    const [deleteErrorModalOpen, setDeleteErrorModalOpen] = useState(false);
    const [csvFile, setCsvFile] = useState(null); // State to store the selected CSV file
    const [csvFileName, setCsvFileName] = useState(''); // State to store the name of the selected CSV file

    useEffect(() => {
      console.log('Updated selectedFiles:', selectedFiles);
    }, [selectedFiles]);

    const removeFile = (fileNameToRemove) => {
      setSelectedFiles(prevFiles => {
        const filteredFiles = prevFiles.filter(file => file.name !== fileNameToRemove);
        // Update filenames state
        setFileNames(filteredFiles.map(file => file.name));
        return filteredFiles;
      });
    };
// Function to handle file selection
const handleFileChange = (event) => {
  const newFiles = [...event.target.files];
  setSelectedFiles(prevFiles => {
    const existingFileNames = new Set(prevFiles.map(file => file.name));
    const uniqueNewFiles = newFiles.filter(file => !existingFileNames.has(file.name));
    // Update filenames state
    setFileNames([...prevFiles, ...uniqueNewFiles].map(file => file.name));
    return [...prevFiles, ...uniqueNewFiles];
  });
};
// Function to upload the file and get the document URL
// Function to upload the files and get their document URLs
const uploadDocuments = async () => {
  if (!selectedFiles.length) return [];
  const uploadedUrls = [];

  for (const file of selectedFiles) {
    try {
      const formData = new FormData();
      formData.append('file', file); // Ensure this key matches the one used in multer .single('file')
      // Assuming you have a function to get the userId
      const presignedResponse = await fetch(`${API_URL}/api/users/${userId}/uploadDocument`, {
        method: 'POST',
        body: formData,
      });
      const presignedData = await presignedResponse.json();
      // Upload the file to the pre-signed URL
      await fetch(presignedData.presignedUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
        },
        body: file,
      });
      uploadedUrls.push(presignedData.accessUrl); // Collect the access URL for the uploaded file
    } catch (error) {
      console.error('Error uploading document:', error);
      // Optionally handle the error, e.g., by breaking the loop or continuing with the next file
    }
  }

  return uploadedUrls; // Return the array of uploaded document URLs
};

    const handleSaveButtonClick = () => {
      // Implement logic to save the selection
      setIsExpanded(!isExpanded);
  
    };

    useEffect(() => {
      fetch(`${API_URL}/api/contact_types`)
        .then(response => response.json())
        .then(data => setContactTypes(data));
    }, []);

    const handleSaveCurrent = () => {
      // Implement logic to save the current selection with the provided name
      // Add the new selection to the savedSelections state
      setIsSaveCurrentClick(!isSaveCurrentClick);
      setIsExpanded(false);
    };
    
    const { t } = useTranslation();

    const classes = useStyles();
    useEffect(() => {
      // Detect if the user is on a mobile device
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.includes('android') || userAgent.includes('iphone')) {
        setIsMobile(true);
      }
    }, []);

    const handleNewContactClick = () => {
      setModalOpen(true);
  };

  const handleDeleteContactClick = () => {
    if (displayedContactsCount > 0) {
      setDeleteModalOpen(true);
    } else {
      setIsDeleteClicked(true);
      setDeleteErrorModalOpen(true);
    }
};

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    setDeleteErrorModalOpen(false);
};

  const handleModalClose = () => {
      setModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Check if the input field is 'phone_number'
    if (name === "phone_number") {
      // Regular expression to match numbers and '+' sign
      if (/^[0-9+]*$/.test(value)) {
        setNewContact({ ...newContact, [name]: value });
      }
    } else {
      // For all other fields, update the state as before
      setNewContact({ ...newContact, [name]: value });
    }
  };
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, ''); // Clean non-digit characters except '+'
    
    if (phoneNumberString.startsWith('+')) {
      // If the phone number starts with a '+', keep it in the formatted result
      const countryCode = cleaned.slice(0, 1); // Country code with '+'
      const phoneNumber = cleaned.slice(1); // Rest of the phone number without '+'
      const match = phoneNumber.match(/(\d{1,3})(?=\d)/g); // Match groups of digits
  
      if (match) {
        return '+' + countryCode + match.join('-');
      }
    } else {
      // If there's no '+' at the start, just format the number normally
      const match = cleaned.match(/(\d{1,3})(?=\d)/g);
      if (match) {
        return match.join('-');
      }
    }
  
    return phoneNumberString; // Return original if it doesn't match the pattern
  };


const handleFormSubmit = async (event) => {
  event.preventDefault();
  let phoneNumberString = newContact.phone_number;


  // Call uploadDocuments to upload the documents and get their URLs
  const docUrls = await uploadDocuments(); // Note the change here to expect an array

  // Prepare the contact object with formatted phone number, types, and doc_urls
  const contactWithFormattedPhoneAndDocUrls = { 
    ...newContact, 
    phone_number: formatPhoneNumber(phoneNumberString),
    types: Array.isArray(newContact.types) && newContact.types.length > 0 ? newContact.types : [], // Default to an empty array if types are not provided
    doc_urls: docUrls // Use the array of document URLs
  };

  // Post the contact data to the backend
  axios.post(`${API_URL}/api/contacts/${userId}`, contactWithFormattedPhoneAndDocUrls)
      .then(response => {
          setContactsData([...contactsData, contactWithFormattedPhoneAndDocUrls]);
          setModalOpen(false);
          // Reset the newContact state
          setNewContact({ name: '', email: '', phone_number: '', types: [], doc_urls: [] }); // Reset doc_urls as an empty array
          setErrorMessage('');
      })
      .catch(error => {
        setErrorMessage('There was an error with the request.');
        console.error('There was an error!', error);
      });
};


useEffect(() => {
  // This code will run whenever selectedContacts changes
  console.log('Selected contacts:', selectedContacts);
}, [selectedContacts]);

useEffect(() => {
  const savedContactSelections = JSON.parse(localStorage.getItem('savedContactSelections')) || [];
  setSavedContactSelections(savedContactSelections);
}, []); 
const handleSaveNameButtonClick = () => {
  const newSavedSelections = [...savedContactSelections, { name: newSelectionName, contacts: selectedContacts }];
  setSavedContactSelections(newSavedSelections);
  setIsSaveCurrentClick(!isSaveCurrentClick);
  localStorage.setItem('savedContactSelections', JSON.stringify(newSavedSelections));
  console.log(newSelectionName)
  console.log(selectedContacts)
};

const handleDeleteClick = (index) => {
  const newSavedSelections = savedContactSelections.filter((_, i) => i !== index);
  setSavedContactSelections(newSavedSelections);
  localStorage.setItem('savedContactSelections', JSON.stringify(newSavedSelections));
};
const handleSavedSelectionNameChange = (event) => {
  // Update the newSelectionName state based on user input
  setNewSelectionName(event.target.value);
};

const handleDelete = async () => {
  // Send a DELETE request for each selected contact
  const deleteRequests = selectedContacts.map(contact =>
    fetch(`${API_URL}/api/contacts/${contact.contact_id}`, { method: 'DELETE' })
  );

  try {
    // Wait for all requests to complete
    await Promise.all(deleteRequests);

    // Call onDelete with the IDs of the selected contacts
    onDelete(selectedContacts.map(contact => contact.contact_id));

    // Close the delete modal
    handleDeleteModalClose();
  } catch (error) {
    console.error('Error:', error);
  }
};

const handleCsvFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    // Check if the file is readable
    const reader = new FileReader();
    reader.onload = () => {
      setCsvFile(file);
      setCsvFileName(file.name); // Set the name of the selected CSV file
    };
    reader.onerror = () => {
      alert('The selected file could not be read. Please check the file permissions and try again.');
    };
    reader.readAsText(file);
  }
};

// Function to parse and upload CSV file
const handleCsvUpload = () => {
  if (!csvFile) {
      alert('Please select a CSV file.');
      return;
  }

  try {
      Papa.parse(csvFile, {
          header: true,
          complete: async (results) => {
              console.log('Parsed CSV results:', results); // Debugging log
              // Filter out contacts with empty fields and include types and doc_urls as empty arrays
              const contacts = results.data.filter(contact => contact.name && contact.email && contact.phone_number).map(contact => ({
                  userId: userId, // Add userId to each contact
                  name: contact.name,
                  email: contact.email,
                  phone_number: contact.phone_number,
                  types: [], // Include empty array for types
                  doc_urls: [] // Include empty array for doc_urls
              }));

              console.log('Filtered contacts:', contacts); // Debugging log

              const importedContacts = [];
              for (const contact of contacts) {
                  try {
                      const response = await fetch(`${API_URL}/api/contacts/${userId}`, {
                          method: 'POST',
                          headers: {
                              'Content-Type': 'application/json',
                          },
                          body: JSON.stringify(contact),
                      });

                      if (!response.ok) {
                          throw new Error('Failed to import contact');
                      }

                      const result = await response.json();
                      importedContacts.push(result);
                  } catch (error) {
                      console.error('Error importing contact:', error);
                  }
              }

              // Fetch the latest contacts data after the import
              try {
                  const response = await fetch(`${API_URL}/api/contacts/${userId}`);
                  if (!response.ok) {
                      throw new Error('Failed to fetch contacts');
                  }
                  const updatedContacts = await response.json();
                  setContactsData(updatedContacts);
                  alert('Contacts imported successfully.');
                  setCsvFile(null); // Reset the CSV file state
                  setCsvFileName(''); // Reset the CSV file name
              } catch (error) {
                  console.error('Error fetching contacts:', error);
                  alert('Failed to fetch updated contacts. Please refresh the page.');
              }
          },
          error: (error) => {
              console.error('Error parsing CSV file:', error);
              alert('Failed to parse CSV file. Please check the file format.');
          },
      });
  } catch (error) {
      console.error('Error reading CSV file:', error);
      alert('Failed to read CSV file. Please check the file permissions and try again.');
  }
};

const truncateFileName = (fileName) => {
  if (fileName.length > 20) {
    return fileName.substring(0, 17) + '...';
  }
  return fileName;
};

const handleDownloadTemplate = () => {
  const link = document.createElement('a');
  link.href = `${API_URL}/api/templates/download-contact-template`; // Update with the correct endpoint
  link.download = 'ContactImportTemplate.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

    if (isMobile) {
        // Placeholder for mobile content
        return (
          <div
          style={{
            position: 'fixed',
            top: 0,
            left: '0px',
            width: '100%',
            height: 'calc(100% - 61px)',
            flexShrink: 0,
            borderRight: '1px solid #333840',
            background: '#1A2033',
            overflow: 'scroll',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
<Typography variant="body1" style={{ color: 'white', textAlign: 'left', marginTop: '20px', paddingLeft: '15px',  paddingBottom: '15px', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat' }}>
{!displayContactOptions || displayedContactsCount === 0 ? t('Contact Options') : displayedContactsCount === 1 ? `${displayedContactsCount} Contact Selected` : `${displayedContactsCount} Contacts Selected`}
</Typography>
<div style={{ borderTop: '1px solid gray', marginBottom: '10px', marginLeft: '10px'  }}></div>
<div style={{ height: !isExpanded ? '50px' : '220px', marginBottom: '20px', borderRadius: '15px',
  border: '1px solid #2b303b',width: '90%', marginLeft: '10px'}}>
{!isSaveCurrentClick && (
<Button
onClick={handleSaveButtonClick}
style={{
  textTransform: 'capitalize',
  color: '#FFFFFF',
  marginLeft: '2px',
  width: '250px',
  height: '50px',
  justifyContent: 'flex-start', fontFamily: 'Montserrat'
}}
>
{t("Saved Selected Contacts")}
<KeyboardArrowDownIcon />
</Button>
)}
{isExpanded && !isSaveCurrentClick && (
<div style={{ height: isExpanded ? '50px' : '220px', marginBottom: '20px' }}>
<Button onClick={handleSaveCurrent} style={{ textTransform: 'capitalize', color: '#FFF', fontSize: '15px', display: 'flex', justifyContent: 'left' }}>
  <AddNewSelectionIcon marginRight={'10px'} /> {t("Save current")}
</Button>
<div  style={{ borderTop: '1px solid #333840', marginBottom: '10px', marginLeft: '10px' }}></div>
<div className="scrollbar3" style={{overflow: 'hidden', overflowY: 'scroll', maxHeight: '120px'}}>
{savedContactSelections.map((selection, index) => (
<div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
<IconButton key={index} onClick={() => handleContactSelectionClick(selection)} style={{height: '10px', color: '#FFF', fontSize: '14px', marginBottom: '10px', fontFamily: 'Montserrat', justifyContent: 'left', paddingLeft: '15px', display: 'flex', flexDirection: 'column'}}>
{selection.name}
</IconButton>
<IconButton onClick={() => handleDeleteClick(index)} style={{ color: '#FFF' }}>
{/* Replace with your delete icon */}
<UncheckCircle />
</IconButton>
</div>
))}
{savedContactSelections.length === 0 && (
<ListItem>
<Typography variant="body1" style={{ color: '#FFF', fontSize: '14px', fontFamily: 'Montserrat' }}>{t("You don't have any saved selections yet.")}</Typography>
</ListItem>
)}
</div>
</div>
)}
{isSaveCurrentClick && (
<div>
<TextField
  value={newSelectionName}
  onChange={handleSavedSelectionNameChange}
  placeholder="Name for the selection..."
  style={{ textTransform: 'capitalize', color: '#FFFFFF', width: '100%', height: '50px', borderRadius: '15px',border: '1px solid #00D0BD',zIndex: '1008', justifyContent: 'center' }}
  variant="standard"
  InputProps={{
    style: { borderBottom: 'none', width: '250px', color: '#FFF', paddingLeft: '10px', justifyContent: 'center' }, // Remove bottom border
    disableUnderline: true // Disable default underline
  }}
/>
<Button onClick={handleSaveNameButtonClick} variant="contained" style={{ width: '85px', textTransform: 'capitalize', background: '#1F2C40' , marginTop: '8px', position: 'relative', left: '30%', color: '#FFF'}}>
  Save
</Button>
</div>
)}
</div>
          <Button
        onClick={handleNewContactClick}
        style={{
          color: "#FFFFFF",
          flexShrink: 0,
          maxWidth: '225px',
          height: '40px',
          borderRadius: '10px',
          border: '1px solid #1F2C41',
          background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft: '20px',
          marginTop: !isSaveCurrentClick ? '20px' : '60px'
        }}
      >
             <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
            <AddNewSelectionIcon marginRight={'10px'} /> {t('New Contact')}
            </Typography>
          </Button>
         
          <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          PaperComponent={Paper}
          PaperProps={{
              style: {
                  backgroundColor: 'rgb(26, 32, 51)', // background color
                  color: '#fff', // text color
              },
    
          }}
      >
        <div className={classes.dialogPaper}>
          <DialogTitle>{t('Add New Contact')}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
              <form onSubmit={handleFormSubmit}>
                  <TextField
                      margin="dense"
                      name="name"
                      label={t("Name")}
                      type="text"
                      fullWidth
                      value={newContact.name}
                      onChange={handleInputChange}
                      InputProps={{
                          classes: {
                              underline: classes.underline,
                          },
                          style: {
                              color: '#fff', // text color
                          },
                      }}
                      InputLabelProps={{
                          style: {
                              color: '#fff', // text color
                          },
                      }}
                  />
                  <TextField
                      margin="dense"
                      name="email"
                      label= {t("Email Address")}
                      type="email"
                      fullWidth
                      value={newContact.email}
                      onChange={handleInputChange}
                      InputProps={{
                          classes: {
                              underline: classes.underline,
                          },
                          style: {
                              color: '#fff', // text color
                          },
                      }}
                      InputLabelProps={{
                          style: {
                              color: '#fff', // text color
                          },
                      }}
                  />
                  <TextField
                      margin="dense"
                      name="phone_number"
                      label={t("Phone Number")}
                      type="tel"
                      fullWidth
                      value={newContact.phone_number}
                      onChange={handleInputChange}
                      InputProps={{
                          classes: {
                              underline: classes.underline,
                          },
                          style: {
                              color: '#fff', // text color
                          },
                      }}
                      InputLabelProps={{
                          style: {
                              color: '#fff', // text color
                          },
                      }}
                  />
                                          <div style={{marginTop: '10px'}}>
      <input
        accept="application/pdf" // Adjust based on your needs
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" style={{ color: '#FFF', backgroundColor: 'rgb(14, 21, 41)', textTransform: 'capitalize', fontWeight: 300, fontStyle: 'normal', fontFamily: 'Montserrat'}}>
          {t("Upload Files")}
        </Button>
      </label>
      <div>
        {fileNames.map((fileName, index) => (
          <Typography key={index} style={{ marginTop: '10px', color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
            {fileName} <Button style={{textTransform: 'capitalize', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', color: 'red' }} onClick={() => removeFile(fileName)}>Remove</Button>
          </Typography>
        ))}
      </div>
    </div>
                  {errorMessage && <p>{errorMessage}</p>}
                  <DialogActions  className={classes.dialogActions}>
                  <CustomAddCancelButton onClick={handleModalClose} className={classes.button}>{t("Cancel")}</CustomAddCancelButton>
                      <CustomAddCancelButton  type="submit"  className={classes.button}> {t("Add")} </CustomAddCancelButton>
                  </DialogActions>
              </form>
          </DialogContent>
          </div>
      </Dialog>
      <Button
        onClick={handleDeleteContactClick}
        style={{
          color: "#FFFFFF",
          flexShrink: 0,
          maxWidth: '225px',
          height: '40px',
          borderRadius: '10px',
          border: '1px solid #1F2C41',
          background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft: '20px',
          marginTop: !isSaveCurrentClick ? '20px' : '60px'
        }}
      >
             <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
             <FontAwesomeIcon icon={faTrash} color="white" style={{marginRight: '10px'}} /> {t("Delete Contact")}
            </Typography>
          </Button>
          <Button onClick={handleDownloadTemplate} style={{   
                  color: "#FFFFFF",
                  marginLeft: '20px',
                  marginTop: '20px',
                  flexShrink: 0,
                  maxWidth: '225px',
                  height: '40px',
                  textTransform: 'capitalize',
                  borderRadius: '10px',
                  border: '1px solid #1F2C41',
                  background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Montserrat', 
                  fontSize: '16px', 
                  fontStyle: 'normal',
                  fontWeight: '400', 
                  lineHeight: 'normal'}}>
            <Typography style={{display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', }}>Download Template</Typography>
            </Button>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '20px', marginTop: '20px', flexDirection: 'row' }}>
                <input
                    accept=".csv"
                    style={{ display: 'none' }}
                    id="csv-file-input"
                    type="file"
                    onChange={handleCsvFileChange}
                />
                <label htmlFor="csv-file-input">
                <Button variant="contained" component="span" style={{
                  color: "#FFFFFF",
                  flexShrink: 0,
                  maxWidth: '225px',
                  height: '40px',
                  textTransform: 'capitalize',
                  borderRadius: '10px',
                  border: '1px solid #1F2C41',
                  background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginTop: !isSaveCurrentClick ? '5px' : '60px',
                  fontFamily: 'Montserrat', 
                  fontSize: '16px', 
                  fontStyle: 'normal',
                  fontWeight: '400', 
                  lineHeight: 'normal'
                }}>
                  {csvFileName ? <Typography style={{ color: 'green', display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{truncateFileName(csvFileName)}</Typography> : <Typography style={{ color: '#FFF', display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize' }}>{t("Bulk Import")}</Typography>}
                    </Button>
                </label>
                <Button
                onClick={handleCsvUpload}
                style={{
                  color: "#FFFFFF",
                  marginTop: !isSaveCurrentClick ? '5px' : '60px',
                  height: '40px',
                }}
              >
                        <FontAwesomeIcon icon={faUpload} color="white" />
                </Button>
            </div>
                <>
               {isDeleteClicked &&   <Dialog
                open={deleteErrorModalOpen}
                onClose={handleDeleteModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <DialogContent>
                    <Typography>
                    {t("Please select your contacts!")}
                    </Typography>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} className={classes.button}>
                               Ok
                            </Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>}
            </>
          <Dialog
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          PaperComponent={Paper}
          PaperProps={{
              style: {
                  backgroundColor: 'rgb(26, 32, 51)', // background color
                  color: '#fff', // text color
              },
          }}
      >
          <DialogTitle> {t("Delete Contact")}</DialogTitle>
          <DialogContent>
              <Typography>
                 {t("Are you sure you want to delete this contact?")}
              </Typography>
                  <DialogActions>
                      <Button onClick={handleDeleteModalClose} className={classes.button}>
                         {t("Cancel")}
                      </Button>
                      <Button onClick={handleDelete}  className={classes.button}>
                         {t("Delete")}
                      </Button>
                  </DialogActions>
          </DialogContent>
      </Dialog>
          </div>
        );
        } else {
            return (
                <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: '60px',
                  width: '250px',
                  height: '100vh',
                  flexShrink: 0,
                  borderRight: '1px solid #333840',
                  background: '#1A2033',
                  overflow: 'scroll',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
<Typography variant="body1" style={{ color: 'white', textAlign: 'left', marginTop: '20px', paddingLeft: '15px',  paddingBottom: '15px', fontWeight: 300, fontSize: '20px', fontFamily: 'Montserrat' }}>
{!displayContactOptions || displayedContactsCount === 0 ? t('Contact Options') : displayedContactsCount === 1 ? `${displayedContactsCount} Contact Selected` : `${displayedContactsCount} Contacts Selected`}
</Typography>
<div style={{ borderTop: '1px solid gray', marginBottom: '10px', marginLeft: '10px'  }}></div>
<div style={{ height: !isExpanded ? '50px' : '220px', marginBottom: '20px', borderRadius: '15px',
        border: '1px solid #2b303b',width: '90%', marginLeft: '10px'}}>
  {!isSaveCurrentClick && (
    <Button
      onClick={handleSaveButtonClick}
      style={{
        textTransform: 'capitalize',
        color: '#FFFFFF',
        marginLeft: '2px',
        width: '250px',
        height: '50px',
        justifyContent: 'flex-start', fontFamily: 'Montserrat'
      }}
    >
      {t("Saved Selected Contacts")}
      <KeyboardArrowDownIcon />
    </Button>
  )}
{isExpanded && !isSaveCurrentClick && (
    <div style={{ height: isExpanded ? '50px' : '220px', marginBottom: '20px' }}>
      <Button onClick={handleSaveCurrent} style={{ textTransform: 'capitalize', color: '#FFF', fontSize: '15px', display: 'flex', justifyContent: 'left' }}>
        <AddNewSelectionIcon marginRight={'10px'} />{t("Save current")}
      </Button>
      <div  style={{ borderTop: '1px solid #333840', marginBottom: '10px', marginLeft: '10px' }}></div>
      <div className="scrollbar3" style={{overflow: 'hidden', overflowY: 'scroll', maxHeight: '120px'}}>
  {savedContactSelections.map((selection, index) => (
     <div key={index} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
  <IconButton key={index} onClick={() => handleContactSelectionClick(selection)} style={{height: '10px', color: '#FFF', fontSize: '14px', marginBottom: '10px', fontFamily: 'Montserrat', justifyContent: 'left', paddingLeft: '15px', display: 'flex', flexDirection: 'column'}}>
  {selection.name}
</IconButton>
    <IconButton onClick={() => handleDeleteClick(index)} style={{ color: '#FFF' }}>
    {/* Replace with your delete icon */}
    <UncheckCircle />
  </IconButton>
  </div>
  ))}
  {savedContactSelections.length === 0 && (
    <ListItem>
      <Typography variant="body1" style={{ color: '#FFF', fontSize: '14px', fontFamily: 'Montserrat' }}>{t("You don't have any saved selections yet.")}</Typography>
    </ListItem>
  )}
</div>
    </div>
)}
  {isSaveCurrentClick && (
    <div>
      <TextField
        value={newSelectionName}
        onChange={handleSavedSelectionNameChange}
        placeholder="Name for the selection..."
        style={{ textTransform: 'capitalize', color: '#FFFFFF', width: '100%', height: '50px', borderRadius: '15px',border: '1px solid #00D0BD',zIndex: '1008', justifyContent: 'center' }}
        variant="standard"
        InputProps={{
          style: { borderBottom: 'none', width: '250px', color: '#FFF', paddingLeft: '10px', justifyContent: 'center' }, // Remove bottom border
          disableUnderline: true // Disable default underline
        }}
      />
      <Button onClick={handleSaveNameButtonClick} variant="contained" style={{ width: '85px', textTransform: 'capitalize', background: '#1F2C40' , marginTop: '8px', position: 'relative', left: '30%', color: '#FFF'}}>
      {t("Save")}
      </Button>
    </div>
  )}
</div>
                <Button
              onClick={handleNewContactClick}
              style={{
                color: "#FFFFFF",
                flexShrink: 0,
                maxWidth: '225px',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginLeft: '20px',
                marginTop: !isSaveCurrentClick ? '20px' : '60px'
              }}
            >
                   <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                  <AddNewSelectionIcon marginRight={'10px'} /> {t('New Contact')}
                  </Typography>
                </Button>
                <Dialog
                open={modalOpen}
                onClose={handleModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
          
                }}
            >
              <div className={classes.dialogPaper}>
                <DialogTitle>{t('Add New Contact')}</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            margin="dense"
                            name="name"
                            label={t("Name")}
                            type="text"
                            fullWidth
                            value={newContact.name}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    underline: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        <TextField
                            margin="dense"
                            name="email"
                            label={t("Email Address")}
                            type="email"
                            fullWidth
                            value={newContact.email}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    underline: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                        <TextField
                            margin="dense"
                            name="phone_number"
                            label={t("Phone Number")}
                            type="tel"
                            fullWidth
                            value={newContact.phone_number}
                            onChange={handleInputChange}
                            InputProps={{
                                classes: {
                                    underline: classes.underline,
                                },
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#fff', // text color
                                },
                            }}
                        />
                     
                        <div style={{marginTop: '10px'}}>
      <input
        accept="application/pdf" // Adjust based on your needs
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={handleFileChange}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span" style={{ color: '#FFF', backgroundColor: 'rgb(14, 21, 41)', textTransform: 'capitalize', fontWeight: 300, fontStyle: 'normal', fontFamily: 'Montserrat'}}>
          {t("Upload Files")}
        </Button>
      </label>
      <div>
        {fileNames.map((fileName, index) => (
          <Typography key={index} style={{ marginTop: '10px', color: '#FFF', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat' }}>
            {fileName} <Button style={{textTransform: 'capitalize', fontWeight: 300, fontSize: '16px', fontStyle: 'normal', fontFamily: 'Montserrat', color: 'red' }} onClick={() => removeFile(fileName)}>Remove</Button>
          </Typography>
        ))}
      </div>
    </div>
                       
                        <DialogActions  className={classes.dialogActions}>
                        <CustomAddCancelButton onClick={handleModalClose} className={classes.button}> {t("Cancel")} </CustomAddCancelButton>
                            <CustomAddCancelButton  type="submit"  className={classes.button}> {t("Add")} </CustomAddCancelButton>
                        </DialogActions>
                    </form>
                </DialogContent>
                </div>
            </Dialog>
            <Button
              onClick={handleDeleteContactClick}
              style={{
                color: "#FFFFFF",
                flexShrink: 0,
                maxWidth: '225px',
                height: '40px',
                borderRadius: '10px',
                border: '1px solid #1F2C41',
                background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '10px',
                paddingRight: '10px',
                marginLeft: '20px',
                marginTop: !isSaveCurrentClick ? '20px' : '60px'
              }}
            >
                   <Typography variant="h6" style={{ display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize',  }}>
                   <FontAwesomeIcon icon={faTrash} color="white" style={{marginRight: '10px'}} />{t("Delete Contact")}
                  </Typography>
                </Button>
                <Button onClick={handleDownloadTemplate} style={{   
                  color: "#FFFFFF",
                  marginLeft: '20px',
                  marginTop: '20px',
                  flexShrink: 0,
                  maxWidth: '225px',
                  height: '40px',
                  textTransform: 'capitalize',
                  borderRadius: '10px',
                  border: '1px solid #1F2C41',
                  background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  fontFamily: 'Montserrat', 
                  fontSize: '16px', 
                  fontStyle: 'normal',
                  fontWeight: '400', 
                  lineHeight: 'normal'}}>
            <Typography style={{display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', }}>Download Template</Typography>
            </Button>
                <div style={{ display: 'flex', alignItems: 'flex-start', marginLeft: '20px', marginTop: '20px', flexDirection: 'row' }}>
                <input
                    accept=".csv"
                    style={{ display: 'none' }}
                    id="csv-file-input"
                    type="file"
                    onChange={handleCsvFileChange}
                />
                <label htmlFor="csv-file-input">
                <Button variant="contained" component="span" style={{
                  color: "#FFFFFF",
                  flexShrink: 0,
                  maxWidth: '225px',
                  height: '40px',
                  textTransform: 'capitalize',
                  borderRadius: '10px',
                  border: '1px solid #1F2C41',
                  background: 'linear-gradient(180deg, #2A2F3D 0%, rgba(35, 40, 51, 0.00) 100%)',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginTop: !isSaveCurrentClick ? '5px' : '60px',
                  fontFamily: 'Montserrat', 
                  fontSize: '16px', 
                  fontStyle: 'normal',
                  fontWeight: '400', 
                  lineHeight: 'normal'
                }}>
                 {csvFileName ? <Typography variant="h6" style={{ color: 'green', display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize', maxWidth: '140px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{truncateFileName(csvFileName)}</Typography> : <Typography style={{ color: '#FFF', display: 'flex', fontFamily: 'Montserrat', fontSize: '16px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', textTransform: 'capitalize' }}>{t("Bulk Import")}</Typography>}
                    </Button>
                </label>
                <Button
                onClick={handleCsvUpload}
                style={{
                  color: "#FFFFFF",
                  marginTop: !isSaveCurrentClick ? '5px' : '60px',
                  height: '40px',
                }}
              >
                        <FontAwesomeIcon icon={faUpload} color="white" />
                </Button>
            </div>
                <>
               {isDeleteClicked &&   <Dialog
                open={deleteErrorModalOpen}
                onClose={handleDeleteModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <DialogContent>
                    <Typography>
                     {t("Please select your contacts!")}
                    </Typography>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} className={classes.button}>
                               Ok
                            </Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>}
            </>
                <Dialog
                open={deleteModalOpen}
                onClose={handleDeleteModalClose}
                PaperComponent={Paper}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgb(26, 32, 51)', // background color
                        color: '#fff', // text color
                    },
                }}
            >
                <DialogTitle>{t("Delete Contact")}</DialogTitle>
                <DialogContent>
                    <Typography>
                       {t("Are you sure you want to delete this contact?")}
                    </Typography>
                        <DialogActions>
                            <Button onClick={handleDeleteModalClose} className={classes.button}>
                               {t("Cancel")}
                            </Button>
                            <Button onClick={handleDelete}  className={classes.button}>
                            {t("Delete")}
                            </Button>
                        </DialogActions>
                </DialogContent>
            </Dialog>
                </div>
            );
            };
        };
      
        export default ContactActions;