// Import dependencies at the top
import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from './translations.json';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Firebase imports (move these to the top as well)
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";

// Initialize i18n
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: 'en-US',
    interpolation: { escapeValue: false }
  });

// Add Google Fonts
const googleFontsLink = document.createElement('link');
googleFontsLink.href = 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap';
googleFontsLink.rel = 'stylesheet';
document.head.appendChild(googleFontsLink);

// Create Material-UI theme
const theme = createTheme({
  // Your theme customization
});

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBYRoEWb1Xet4eBZbsI1C5RgweKi9YM2AE",
  authDomain: "myapp-a4c10.firebaseapp.com",
  projectId: "myapp-a4c10",
  storageBucket: "myapp-a4c10.appspot.com",
  messagingSenderId: "17631405046",
  appId: "1:17631405046:web:6b361237a562a690ebddd7",
  measurementId: "G-9F2SJRGPSG"
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Firebase custom hook for messaging
const useFirebase = () => {
  const [firebase, setFirebase] = useState(null);

  useEffect(() => {
    const initializeFirebaseMessaging = async () => {
      if (await isSupported()) {
        const messaging = getMessaging(app);
        setFirebase({ messaging, getToken, onMessage });
      }
    };

    initializeFirebaseMessaging();
  }, []);

  return firebase;
};

// FirebaseApp component to handle notifications
const FirebaseApp = () => {
  const firebase = useFirebase();

  useEffect(() => {
    if (firebase) {
      const { messaging, getToken, onMessage } = firebase;

      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then((currentToken) => {
            if (currentToken) {
              console.log("FCM Token:", currentToken);
              // Send token to your server here
            } else {
              console.log('No registration token available.');
            }
          }).catch(console.error);

          onMessage(messaging, (payload) => {
            console.log('Foreground Notification:', payload);
            new Notification(payload.notification.title, { body: payload.notification.body });
          });
        }
      });
    }
  }, [firebase]);

  return null;
};

// Render React app
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Suspense fallback="Loading...">
          <ThemeProvider theme={theme}>
            <App />
            <FirebaseApp />
          </ThemeProvider>
        </Suspense>
      </I18nextProvider>
    </BrowserRouter>
    <ToastContainer position="top-center" autoClose={3000} limit={3} />
  </React.StrictMode>,
  document.getElementById('root')
);
