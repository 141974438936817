/* Importing necessary dependencies and components */
import React, { useEffect, useState } from 'react';
import SearchBar from './SearchBar';
import '../styles/HomePage.css';
import SideBar from './SideBar';
import MainContent from './MainContent';
import { Avatar, Button, List, Popover, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faSignOutAlt, faLanguage } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from './LanguageSwitcher';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Settings from './Settings';
import { jsPDF } from "jspdf";
import ContactsContent from './ContactsContent';
import ContactActions from './ContactActions';
import MergedEventCalendar from './MergedEventCalendar';
import AccountSettingsModal from './AccountSettingsModal';
import FinanceContent from './FinanceContent'
/* Defining the HomePage component */
const HomePage = ({ isLoggedIn, userId, username, email, setIsLoggedIn, assetsCountMessage, permissions, setTriggerReload, isIOS, handleEnableNotifications }) => {
  /* state variables for search term, number of rooms, price range, asset type, and amenities */
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNumberOfRooms, setFilteredNumberOfRooms] = useState('');
  const [filteredPriceRange, setFilteredPriceRange] = useState([0, 1000000]);
  const [filteredAssetType, setFilteredAssetType] = useState('');
  const [filteredAmenities, setFilteredAmenities] = useState([]);
  const [filteredFurniture, setFilteredFurniture] = useState([]);
  const [filteredAssetCondition, setFilteredAssetCondition] = useState([]);
  const [availabilityFilter, setAvailabilityFilter] = useState({ startDate: null, endDate: null });
  const [filteredHeating, setFilteredHeating] = useState([]);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [filteredLanguages, setFilteredLanguages] = useState([]);
  const[filteredHidden, setFilteredHidden] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [contactsData, setContactsData] = useState([]);
  const [displayContactOptions, setDisplayContactOptions] = useState(false);
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [contactName, setContactName] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [eventTitle, setEventTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [contactTypeName, setContactTypeName] = useState([]);
  const [contactTypeId, setContactTypeId] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  // State variable for selected type
const [selectedType, setSelectedType] = useState(null);
const [assignModalOpen, setAssignModalOpen] = useState(false);
const [hiddenAssetsCount, setHiddenAssetsCount] = useState(0);
const [isModalOpen, setModalOpen] = useState(false);
const [filteredSaleOrRent, setFilteredSaleOrRent] = useState([]);
const [updateTrigger, setUpdateTrigger] = useState(false);
const [state, setState] = useState([
  {
    startDate: undefined,
    endDate: undefined,
    key: 'selection'
  }
]);

  // State to control the visibility of LanguageSwitcher
  const [showLanguageSwitcher, setShowLanguageSwitcher] = useState(false);
  const [notificationCount, setNotificationCount] = useState(() => {
    const savedCount = localStorage.getItem('notificationCount');
    return savedCount !== null ? parseInt(savedCount, 10) : 0;
  });
  // Function to toggle the LanguageSwitcher visibility
  const toggleLanguageSwitcher = () => {
    setShowLanguageSwitcher(!showLanguageSwitcher);
  };
  // Update both state and localStorage when notificationCount changes
  const updateNotificationCount = (count) => {
    setNotificationCount(count);
    localStorage.setItem('notificationCount', count.toString());
  };

  // Effect hook to update localStorage when notificationCount changes
  useEffect(() => {
    localStorage.setItem('notificationCount', notificationCount.toString());
  }, [notificationCount]);

const handleOpenModal = () => {
  setModalOpen(true);
  setAnchorEl(null);
};

const handleCloseModal = () => {
  setModalOpen(false);
};
// Update hiddenAssetsCount whenever assets changes
useEffect(() => {
  const count = assets.filter(asset => asset.hidden).length;
  setHiddenAssetsCount(count);
}, [assets]);
  /* a function to handle changes in the search term */
  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  /* a function to handle changes in the number of rooms */
  const onFilterChange = (numberOfRooms) => {
    setFilteredNumberOfRooms(numberOfRooms);
  };

  /* a function to handle changes in the price range */
  const onPriceChange = (filteredPriceRange) => {
    setFilteredPriceRange(filteredPriceRange);
  };

  /* a function to handle changes in the asset type */
  const onAssetTypeChange = (assetType) => {
    setFilteredAssetType(assetType);
  };

  /* Define a function to handle changes in the amenities */
  // Define your mapping of translated values to English values
  const amenityMapping = {
    'Teretana': 'Gym',
    'Lift': 'Lift',
    'Klima': 'Airco',
    'TV': 'TV',
    'Dozvoljeni kućni ljubimci': 'Pet Friendly',
    'Veš mašina': 'Washing Machine',
    'Sušilica': 'Drying Machine',
    'Dishwasher': 'Dishwasher',
    'Garage': 'Garage',
    'Terasa': 'Terrace',
    'Parking': 'Parking',
  };
  
  const languageMapping = {
    'Engleski': 'English',
    'Francuski': 'French',
    'Nemački': 'German',
    'Italijanski': 'Italian',
    'Španski': 'Spanish',
  }

  const furnitureMapping = {
    'Namešten': 'Furnished',
    'Polunamešten': 'Semi-furnished',
    'Prazan': 'Unfurnished',
  }
  
  const saleRentMapping = {
    'Prodaja': 'Sale',
    'Izdavanje': 'Rent'
  }
  
  const asset_conditionMapping = {
    'Originalno stanje': 'Original state',
    'Renovirano': 'Renovated',
    'Luksuzno': 'Lux'
  }

  const heatingMapping = {
    'Centralno grejanja': 'Central heating',
    'Gas': 'Gas',
    'Toplotne pumpe': 'Heat pumps',
    'Norveški radijatori': 'Norwegian radiators',
    'EG': 'EG',
    'TA': 'TA'
  }

  const hiddenMapping = {
    'Prikaži skrivene imovine': 'Show Hidden Assets',
  };

  const statusMapping = {
    'Novo': 'New',
    "Oglašeno": 'Published',
    'Predugovor': 'Pre-contract',
    'Prodato': 'Sold',
    'Izdato': 'Leased',
  }
  // Then, in your onAmenitiesChange function...
  const onAmenitiesChange = (amenity) => {
    // Get the English value for the selected amenity
    const englishAmenity = amenityMapping[amenity] || amenity;
    const updatedAmenities = [...filteredAmenities];
  
    const index = updatedAmenities.indexOf(englishAmenity);
  
    if (index === -1) {
      updatedAmenities.push(englishAmenity);
    } else {
      updatedAmenities.splice(index, 1);
    }
  
    setFilteredAmenities(updatedAmenities);
  };

  const onFurnitureChange = (furniture) => {

    const englishFurniture = furnitureMapping[furniture] || furniture;
    const updatedFurniture = [...filteredFurniture];

    const index = updatedFurniture.indexOf(englishFurniture);
    if (index === -1) {
      updatedFurniture.push(furniture);
    } else {
      updatedFurniture.splice(index, 1);
    }
    setFilteredFurniture(updatedFurniture);
  };

  const onSaleRentChange = (option) => {

    const englishSaleRent = saleRentMapping[option] || option;
    const updatedSaleRent = [...filteredSaleOrRent];

    const index = updatedSaleRent.indexOf(englishSaleRent);
    if (index === -1) {
      updatedSaleRent.push(option);
    } else {
      updatedSaleRent.splice(index, 1);
    }
    setFilteredSaleOrRent(updatedSaleRent);
  };

      // Function to handle click on a grid item
      const handleGridItemClick = (asset) => {
        setSelectedAsset(asset); // Set the selected asset when clicked
      };
  
      const handleListItemClick = (asset) => {
        setSelectedAsset(asset); // Set the selected asset when clicked
      }

  const onAssetConditionChange = (asset_condition) => {

    const englishAssetCondition = asset_conditionMapping[asset_condition] || asset_condition;
    const updatedAssetCondition = [...filteredAssetCondition];

    const index = updatedAssetCondition.indexOf(englishAssetCondition);
    if (index === -1) {
      updatedAssetCondition.push(asset_condition);
    } else {
      updatedAssetCondition.splice(index, 1);
    }
    setFilteredAssetCondition(updatedAssetCondition);

  }

  const onAvailabilityChange = (startDate, endDate) => {
    setAvailabilityFilter({ startDate, endDate });
  };


  const onHeatingChange = (heating) => {

    const englishHeating = heatingMapping[heating] || heating;
    const updatedHeating = [...filteredHeating];

    const index = updatedHeating.indexOf(englishHeating);
    if (index === -1) {
      updatedHeating.push(heating);
    } else {
      updatedHeating.splice(index, 1);
    }
    setFilteredHeating(updatedHeating);
  };
  
  const onStatusChange = (status) => {

    const enlglishStatus = statusMapping[status] || status;
    const updatedStatus = [...filteredStatus];

    const index = updatedStatus.indexOf(enlglishStatus);
    if (index === -1) {
      updatedStatus.push(status);
    } else {
      updatedStatus.splice(index, 1);
    }
    setFilteredStatus(updatedStatus);
  };

  const onLanguageChange = (language) => {
    const englishLanguage = languageMapping[language] || language;
    const updatedLanguage = [...filteredLanguages];
  
    const index = updatedLanguage.indexOf(englishLanguage);
    if (index === -1) {
      updatedLanguage.push(englishLanguage);
    } else {
      updatedLanguage.splice(index, 1);
    }
  
    setFilteredLanguages(updatedLanguage);
  };

  const onHideAssetChange = (hidden) => {
    const englishHidden = hiddenMapping[hidden] || hidden;
    const updatedHidden = [...filteredHidden];
  
    const index = updatedHidden.indexOf(englishHidden);
    if (index === -1) {
      updatedHidden.push(hidden);
    } else {
      updatedHidden.splice(index, 1);
    }
  
    setFilteredHidden(updatedHidden);
  
    // Update assets state
    const updatedAssets = assets.map(asset => {
      if (asset.hidden === hidden) {
        return { ...asset, hidden: !asset.hidden };
      }
      return asset;
    });
    setAssets(updatedAssets);
  };

    // Function to clear all filters
    const handleClearAllFilters  = () => {
      setFilteredNumberOfRooms('');
      setFilteredPriceRange([0, 1000000]);
      setFilteredAssetType('');
      filteredLanguages.splice(0, filteredLanguages.length);
      setFilteredLanguages([...filteredLanguages]);

      filteredAmenities.splice(0, filteredAmenities.length);
      setFilteredAmenities([...filteredAmenities]);
      filteredFurniture.splice(0, filteredFurniture.length);
      setFilteredFurniture([...filteredFurniture]);
      filteredSaleOrRent.splice(0, filteredSaleOrRent.length);
      setFilteredSaleOrRent([...filteredSaleOrRent]);
      filteredAssetCondition.splice(0, filteredAssetCondition.length);
      setFilteredAssetCondition([...filteredAssetCondition]);
      filteredHeating.splice(0, filteredHeating.length);
      setFilteredHeating([...filteredHeating]);
      filteredStatus.splice(0, filteredStatus.length);
      setFilteredStatus([...filteredStatus]);
      filteredHidden.splice(0, filteredHidden.length);
      setFilteredHidden([...filteredHidden]);
      setAvailabilityFilter({ startDate: null, endDate: null });
      setState([
        {
          startDate: undefined,
          endDate: undefined,
          key: 'selection'
        }
      ]);
 // Set filteredAmenities directly to an empty array
    };

    const handleLogout = () => {
      // Clear user's authentication state
      setIsLoggedIn(false);
      localStorage.removeItem('userId');
      console.log("you clicked on log out icon")
    };

     // Closing the popover
  const handleClosePopover = () => {
    setAnchorEl(null);
    setShowLanguageSwitcher(false);
  };
    // Function to handle profile button click and set the anchor element for popover
    const handleProfileClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

  const open = Boolean(anchorEl);
  const id = open ? 'sort-by-popover' : undefined;

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t } = useTranslation();

  const [showSettingsComponent, setShowSettingsComponent] = useState(false);
  const [showContactActions, setShowContactActions] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedMenuItem, setSelectedMenuItem] = useState('calendar');
  const [showSelectedContactsOnly, setShowSelectedContactsOnly] = useState(false);
  const [displayedContactsCount, setDisplayedContactsCount] = useState(0);
  const [displayedAssetsCount, setDisplayedAssetsCount] = useState(0);
  const [assetTypes, setAssetTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add this line
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true); // Set loading to true before fetching data
        const response = await fetch(`${API_URL}/api/filter/assetTypeList`);
        const data = await response.json();
  
        if (!data.assetTypeList || !Array.isArray(data.assetTypeList)) {
          throw new Error('Unexpected response format');
        }
  
        const uniqueAssetTypes = [...new Set(data.assetTypeList)]; // No need to map here
        const translatedAssetTypes = uniqueAssetTypes.map(assetType => t(assetType));
        setAssetTypes(translatedAssetTypes);
        setIsLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data from the backend', error);
        setIsLoading(false); // Set loading to false if an error occurs
      }
    };
  
    fetchData();
  }, [setAssetTypes, userId, t]);
  

  const toogleSettingsComponent = () => {
    setShowSettingsComponent(!showSettingsComponent);
    console.log("selected assets you can now use for what you want ", selectedAssets);
    setShowSelectedOnly(true);
    setDisplayedAssetsCount(selectedAssets.length); // Add this line
  };

  
  const toogleContactActions = () => {
    setShowContactActions(!showContactActions);
     setDisplayContactOptions(!displayContactOptions);
    console.log("selected contacts you can now use for what you want ", selectedContacts);
    setShowSelectedContactsOnly(true);
    setDisplayedContactsCount(selectedContacts.length); // Add this line
  };
    // Function to close the Settings component
    const closeSettingsComponent = () => {
      setShowSettingsComponent(false);
    };

        // Function to close the closeContactActions component
        const closeContactActions = () => {
          setShowContactActions(false);
        };
    const exportSelectedAssetsAsPDF = () => {
      // Initialize PDF document
      const doc = new jsPDF();
    
      // Set initial y-coordinate for text placement
      let y = 10;
    
      // Loop through selectedAssets and add them to the PDF
      selectedAssets.forEach((asset, index) => {
        doc.text(`Asset Name: ${asset.name}`, 10, y);
        y += 10;
        doc.text(`Price: ${asset.price}`, 10, y);
        y += 10;
        // Add additional asset details
        doc.text(`Asset Type: ${asset.AssetType}`, 10, y);
        y += 10;
        doc.text(`Amenities: ${asset.amenities.join(', ')}`, 10, y);
        y += 10;
        
        // Add description label and split the description into multiple lines to fit within the PDF
        doc.text("Description:", 10, y);
        // Add beofre  the description
        y += 10;
        const descriptionLines = doc.splitTextToSize(asset.description, 180); // Adjust width as needed
        descriptionLines.forEach(line => {
          doc.text(line, 10, y);
          y += 7; // Increment y-coordinate for next line
        });
        // Add space after the description
        y += 5;
        doc.text(`Host Language: ${asset.hostLanguage.join(', ')}`, 10, y);
        y += 10;
        // Add more details as needed
        doc.text(`Number of rooms: ${asset.noOfRooms}`, 10, y);
        y += 10;
        doc.text(`Square footage: ${asset.squareFootage}`, 10, y);
        y += 10;
        // Add a page break after each asset
        if (index < selectedAssets.length - 1) {
          doc.addPage();
          y = 10; // Reset y-coordinate for next page
        }
      });
    
      // Save or download the PDF
      doc.save('selected_assets.pdf');
    };
    
    const handleSelectionClick = (selection) => {
      setSelectedAssets(selection.assets);
      setShowSelectedOnly(true);
    };
  /* Render the HomePage component. It includes a SearchBar, SideBar, and MainContent component. */
  /* The state variables and handler functions are passed as props to these child components. */
  
  const handleContactSelectionClick = (contact) => {
    setSelectedContacts([contact]);
    setShowSelectedContactsOnly(true);
  };
  const onDelete = (deletedContactIds) => {
    // Update the contactsData state to remove the deleted contacts
    const updatedContactsData = contactsData.filter(contact =>
      !deletedContactIds.includes(contact.contact_id)
    );
  
    setContactsData(updatedContactsData);
  
    // Also update the selectedContacts state to remove the deleted contacts
    const updatedSelectedContacts = selectedContacts.filter(contact =>
      !deletedContactIds.includes(contact.contact_id)
    );
  
    setSelectedContacts(updatedSelectedContacts);
  
    setDisplayedContactsCount(updatedSelectedContacts.length);
  
    setShowSelectedContactsOnly(false);
    setShowContactActions(false);
  };


  const onDeleteAsset = (id) => {
  
    // Update the contactsData state to remove the deleted contact
    const updatedAssetsData = assets.filter(asset => asset.id !== id);

    setContactsData(updatedAssetsData);
  
    // Also update the selectedContacts state to remove the deleted contact
    const updatedSelectedAssets = selectedAssets.filter(asset => asset.id !== id);

    setSelectedAssets(updatedSelectedAssets);
  

    setDisplayedAssetsCount(updatedSelectedAssets.length);
  

    setShowSelectedOnly(false);
    setShowSettingsComponent(false);
  };

  const onHideAsset = (id) => {
    // Filter out the hidden asset from the assets state
    const updatedAssetsData = assets.filter(asset => asset.id !== id);
  
    setAssets(updatedAssetsData);
  
    // Also filter out the hidden asset from the selectedAssets state
    const updatedSelectedAssets = selectedAssets.filter(asset => asset.id !== id);
  
    setSelectedAssets(updatedSelectedAssets);
  
    // Update the displayedAssetsCount state
    setDisplayedAssetsCount(updatedSelectedAssets.length);
  
    // Reset the showSelectedOnly and showSettingsComponent states
    setShowSelectedOnly(false);
    setShowSettingsComponent(false);
  };

  // Define the handleAssign function
  // Define the handleAssign function
const handleAssign = async (userId, assets) => {
  let alertDispatched = false; // Flag to track if an alert has been dispatched

  try {
    for (const asset of assets) {
      // Check if the asset is already assigned
      const checkResponse = await fetch(`${API_URL}/api/data/${asset.id}/isAssigned/${userId}`);
      const checkData = await checkResponse.json();

      if (checkData.isAssigned) {
        // Fetch the user's email if the asset is already assigned
        const emailResponse = await fetch(`${API_URL}/api/users/${userId}/email`);
        const emailData = await emailResponse.json();

        alert(`You already assigned this asset to user: ${emailData.email}`);
        alertDispatched = true; // Set the flag to true
        break; // Exit the loop early
      } else {
        // Proceed to assign the asset if it is not already assigned
        const assignResponse = await fetch(`${API_URL}/api/users/${userId}/assets`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ assetId: asset.id }), // Only send the assetId
        });

        if (!assignResponse.ok) {
          throw new Error('Error assigning asset');
        }
      }
    }

    if (alertDispatched) {
      return; // Exit the function early if an alert has been dispatched
    }

    // After all assets are processed, close the modal and clear the selected assets
    setSelectedAssets([]);
    setAssignModalOpen(false);
    setShowSelectedOnly(false);
    setDisplayedAssetsCount(0); // Reset the displayed assets count
  } catch (error) {
    console.error('Error processing assets', error);
  }
};


  return (
    <>
      <div className="home-page-container">
      {screenWidth >= 550 && (
      <div className="myProfile" style={{width: '120px', height: '40px', position: 'absolute', top: '10px', right: '0px', display: 'flex', alignItems: 'center'}}>
      <Button 
      onClick={handleProfileClick}
      style={{fontSize: '13px', color: '#fff', textDecoration: 'none', textTransform: 'none', display: 'flex', alignItems: 'center', zIndex: 100, marginBottom: '10px', fontFamily: 'Montserrat'}}>
        <Avatar sx={{ bgcolor: '#3f51b5', marginRight: '5px', width: '20px', height: '20px' }}> {/* User icon */}
            {/* You can also put an actual user image here */}
            <FontAwesomeIcon icon={faUser} style={{width:'20px', height: '20px'}} />
        </Avatar>
        {i18n.t('My Profile')}
    </Button>
    <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        <List style={{ background: 'linear-gradient(180deg, #2A2F3D 100%, rgba(65, 0, 51, 0.00) 100%)', flexDirection: 'column', display: 'flex', width: '195px', }}>
        <Typography component="div" style={{ color: '#FFFFFF', textTransform: 'none', fontSize: '12px', paddingLeft: '5px', fontFamily: 'Montserrat' }}>
        {t('email')}: {email}
        </Typography>
        {/* Display user's username */}
        <Typography component="div" style={{ color: '#FFFFFF', textTransform: 'none', fontSize: '12px' , paddingLeft: '5px', fontFamily: 'Montserrat'}}>
        {t('username')}: {username}
        </Typography>
        <div style={{ borderTop: '1px solid gray', marginTop: '10px', marginBottom: '10px' }}></div>
        {/* Buttons */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Button style={{ color: '#FFFFFF', textTransform: 'none', fontSize: '13px', fontFamily: 'Montserrat' }} className="my-button" onClick={handleOpenModal}>
        <FontAwesomeIcon icon={faCog} style={{ marginRight: '5px', }} />
        {t('Account settings')}
        </Button>
        <Button style={{ color: '#FFFFFF', textTransform: 'none' , fontSize: '13px', fontFamily: 'Montserrat'}} className="my-button" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '5px' }} />
        {t('Log Out')}
        </Button>
        <Button style={{ color: '#FFFFFF', textTransform: 'none' , fontSize: '13px',  fontFamily: 'Montserrat'}} className="my-button" onClick={toggleLanguageSwitcher}>
      <FontAwesomeIcon icon={faLanguage} style={{ marginRight: '5px',  }}/>
      {t('Language preference')}
    </Button>
      </div>
      {showLanguageSwitcher && <LanguageSwitcher setAnchorEl={setAnchorEl} setShowLanguageSwitcher={setShowLanguageSwitcher}/>}
        </List>
        </Popover>
        {isModalOpen && <AccountSettingsModal onClose={handleCloseModal} email={email} username={username} setTriggerReload={setTriggerReload}/>}
      </div>
      )}
{selectedMenuItem === 'assets' ? (
  <>
    <SearchBar updateSearchTerm={handleSearchChange} assetsCountMessage={assetsCountMessage}/>
    <MainContent
      handleGridItemClick={handleGridItemClick}
      handleListItemClick={handleListItemClick}
      searchTerm={searchTerm}
      setUpdateTrigger={setUpdateTrigger}
      updateTrigger={updateTrigger}
      filteredNumberOfRooms={filteredNumberOfRooms}
      filteredPriceRange={filteredPriceRange}
      filteredAssetType={filteredAssetType}
      filteredAmenities={filteredAmenities}
      filteredLanguages={filteredLanguages}
      filteredHidden={filteredHidden}
      filteredFurniture={filteredFurniture}
      filteredSaleOrRent={filteredSaleOrRent}
      filteredAssetCondition={filteredAssetCondition}
      availabilityFilter={availabilityFilter}
      filteredHeating={filteredHeating}
      filteredStatus={filteredStatus}
      onToogleSettingsComponent = {toogleSettingsComponent}
      showSettingsComponent = {showSettingsComponent}
      selectedAssets={selectedAssets}
      setSelectedAssets={setSelectedAssets}
      showSelectedOnly={showSelectedOnly} 
      setShowSelectedOnly={setShowSelectedOnly}
      userId={userId}
      assetTypes={assetTypes}
      assets={assets}
      selectedAsset={selectedAsset}
      setSelectedAsset={setSelectedAsset}
      contactsData={contactsData}
      permissions={permissions}
      setHiddenAssetsCount={setHiddenAssetsCount}
      setContactsData={setContactsData}
    />
  </>
) : selectedMenuItem === 'contacts' ? (
  <ContactsContent   onDelete={onDelete}  userId={userId} contactsData={contactsData} setContactsData={setContactsData} selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts}
  onToogleContactActions={toogleContactActions} showContactActions={showContactActions} showSelectedContactsOnly={showSelectedContactsOnly} setShowSelectedContactsOnly={setShowSelectedContactsOnly}
  displayContactOptions = {displayContactOptions}  setDisplayContactOptions = {setDisplayContactOptions}  displayedContactsCount={displayedContactsCount} assets={assets} setAssets={setAssets}  selectedAsset={selectedAsset} setSelectedAsset={setSelectedAsset} notes={notes} setNotes={setNotes}
  />
) : selectedMenuItem === 'calendar' ? (
  <MergedEventCalendar userId={userId} setSelectedDate={setSelectedDate} selectedDate={selectedDate}
   selectedTime = {selectedTime} setSelectedTime={setSelectedTime} 
   eventTitle={eventTitle} setEventTitle={setEventTitle}
   assets={assets} setAssets={setAssets} 
   contactName = {contactName} setContactName={setContactName} contactTypeName={contactTypeName} setContactTypeName={setContactTypeName}
   contacts={contacts} setContacts={setContacts} selectedContact={selectedContact} setSelectedContact={setSelectedContact}
   contactTypeId={contactTypeId} setContactTypeId={setContactTypeId} notes={notes} setNotes={setNotes} selectedType={selectedType} setSelectedType={setSelectedType} updateNotificationCount={updateNotificationCount} setNotificationCount={setNotificationCount}
   />
  ) : selectedMenuItem === 'finance' && (permissions || []).includes('finance_access') ? (
    <FinanceContent userId={userId} permissions={permissions} contactsData={contactsData} assetTypes={assetTypes} setSelectedAsset={setSelectedAsset} selectedAsset={selectedAsset} setContactsData={setContactsData} setUpdateTrigger={setUpdateTrigger} />
  ) : null}
      </div>
      <div className="sidebar-container">
        <SideBar
          onDataFiltered={onFilterChange}
          onPriceFiltered={onPriceChange}
          onAssetTypeFiltered={onAssetTypeChange}
          onAmenitiesFiltered={onAmenitiesChange}
          onFurnitureFiltered={onFurnitureChange}
          onSaleRentFiltered={onSaleRentChange}
          onAssetConditionFiltered={onAssetConditionChange}
          onAvailabilityFiltered={onAvailabilityChange}
          onHeatingFiltered={onHeatingChange}
          onStatusFiltered={onStatusChange}
          onHiddenAssetsFiltered={onHideAssetChange}
          onLanguageFiltered={onLanguageChange}
          onClearAllFilters={handleClearAllFilters}
          isLoggedIn={isLoggedIn}
          userId={userId}
          onLogout={handleLogout}
          username={username}
          email={email}
          setIsLoggedIn={setIsLoggedIn}
          // Pass the state indicating whether the Settings component is open or closed
          showSettingsComponent={showSettingsComponent}
          onToogleSettingsComponent={toogleSettingsComponent}
          onClose={closeSettingsComponent}
          handleSelectionClick={handleSelectionClick}
          handleContactSelectionClick= {handleContactSelectionClick}
          setShowSelectedOnly={setShowSelectedOnly}
          setSelectedAssets={setSelectedAssets}
          setSelectedMenuItem={setSelectedMenuItem}
          selectedMenuItem={selectedMenuItem}
          contactsData={contactsData}
          setContactsData={setContactsData}
          showContactActions={showContactActions}
          onToogleContactActions={toogleContactActions}
          selectedContacts={selectedContacts}
          displayContactOptions={displayContactOptions}
          displayedContactsCount={displayedContactsCount}
          displayedAssetsCount={displayedAssetsCount}
          onDelete={onDelete}
          onDeleteAsset={onDeleteAsset}
          onHideAsset={onHideAsset}
          assetTypes={assetTypes}
          setAssetTypes={setAssetTypes}
          setAssets={setAssets}
          setSelectedContacts={setSelectedContacts}
          permissions={permissions}
          hiddenAssetsCount={hiddenAssetsCount}
          assignModalOpen={assignModalOpen}
          setAssignModalOpen={setAssignModalOpen}
          notificationCount={notificationCount}
          updateNotificationCount={updateNotificationCount}
          handleEnableNotifications={handleEnableNotifications}
          isIOS={isIOS}
          state={state}
          setState={setState}
        />
      </div>
            {/* Render the Settings component if showSettingsComponent is true */}
            {showSettingsComponent && !isLoading && (
  <Settings
        onClose={closeSettingsComponent} // Pass a function to close the Settings component
          // Add other props if needed
          selectedAssets={selectedAssets} // Pass selected assets data
          exportSelectedAssets={exportSelectedAssetsAsPDF } // Pass export function
          setSelectedAssets={setSelectedAssets}
          handleSelectionClick={handleSelectionClick}
          setShowSelectedOnly={setShowSelectedOnly}
          showSelectedOnly={showSelectedOnly}
          assetTypes={assetTypes}
          userId={userId}
          setAssets={setAssets}
          onDeleteAsset={onDeleteAsset}
          onHideAsset={onHideAsset}
          displayedAssetsCount={displayedAssetsCount}
          handleAssign={handleAssign}
          assignModalOpen={assignModalOpen}
          setAssignModalOpen={setAssignModalOpen}
          permissions={permissions}
        />
      )}

      {showContactActions && (
        <ContactActions  onDelete={onDelete} onClose = {closeContactActions} handleContactSelectionClick = {handleContactSelectionClick}  displayedContactsCount={displayedContactsCount}  displayContactOptions={displayContactOptions} contactsData = {contactsData} setContactsData={setContactsData} userId={userId} selectedContacts={selectedContacts} setSelectedContacts={setSelectedContacts}/>
      )}
    </>
  );
};

/* Export the HomePage component as the default export */
export default HomePage;
